import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';

export const faqQuery = graphql`
  query faqQuery($uid: String) {
    prismic {
      allFaq_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            meta_title
            meta_keywords
            meta_description
            title
            content
            body {
              ... on PRISMIC_Faq_pageBodyFrequency_asked_questions {
                label
                fields {
                  question
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`

const RenderBody = ({ faq }) => {
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 mt-4'>
            <div className='panel panel-default'>
              <div className='panel-heading p-5'><h1>{faq.title}</h1></div>
              <div className='panel-body p-5'>
                <div className='mb-3'>{RichText.render(faq.content, linkResolver)}</div>
              </div>
              <ul class="list-group">
                {faq.body &&
                  <React.Fragment>
                    {faq.body.map((slice, index) => {
                      return (
                        <li className='list-group-item' key={`slice-${index}`}>
                          {slice.fields.map((faqs, index) => {
                            return (
                              <React.Fragment>
                                <div class='h3'>{faqs.question}</div>
                                <div class='mb-3'>{RichText.render(faqs.answer, linkResolver)}</div>
                              </React.Fragment>
                            )
                          })}
                        </li>
                      )
                    })}
                  </React.Fragment>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const FAQ = props => {
  const doc = props.data.prismic.allFaq_pages.edges.slice(0,1).pop();
  if(!doc) return null;

  return (
    <Layout>
      <SEO title={doc.node.meta_title} keywords={doc.node.meta_keywords} description={doc.node.meta_description} lang='en-us' />
      <RenderBody faq={doc.node} />
    </Layout>
  )
}
export default FAQ
